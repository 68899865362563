import { AxiosRequestConfig } from 'axios';
import { config } from '../../config';
import { AxiosInstance } from '../axios-instance';
export class CommonAxiosServiceMDM {
    URL = config.APP_MDM_SERVICE_URL;
    axiosPostCall = async (urlEndPoint: string, data?: any, config?: AxiosRequestConfig) => {
        return await AxiosInstance.post(this.URL + '' + urlEndPoint, data, config)
            .then(res => {
                return res.data;
            }).catch(err => {
                throw new Error(err.message);
            })
    }
}