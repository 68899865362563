import { faArrowRightArrowLeft, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Col, Row } from 'antd'
import React, { useState } from 'react'
import ImageAvatar from '../../images/DataLynx-Logo.png';
import './profile-list.css'


export default function ProfilesList() {
    const [imageUrl, setImageUrl] = useState<any>(ImageAvatar)

    return (
        <div className='bg-img'>
            <Row justify='center' style={{ paddingTop: '50px' }}>
                <div className="profile-picture">
                    <img src={imageUrl} />
                </div>
            </Row>
            <div>


                <Row justify='center' style={{ padding: '10px' }} >
                    <div className="edit-resize-button">
                        <Col >
                            <Button size='small' shape='round' >PROFILE</Button>
                            <Button size='small' shape='round' style={{marginLeft:'5px'}} >RESOURCES </Button>
                        </Col>
                    </div>
                </Row>
                <Row justify='center'>
                    <div>
                        <Col>
                            <span><span style={{ fontWeight: 500, fontSize: '20px' }}>EDIT PROFILE </span>
                                <FontAwesomeIcon icon={faCircleArrowLeft} />
                            </span>
                        </Col>
                        <Col >
                            <div style={{ textAlign: 'center' }}>
                                Build your profile
                            </div>
                        </Col>
                    </div>
                </Row>
                <Row justify='center'>
                    <div className="custom-button" >
                        <Col>
                            <Button size='large' >View Profile <FontAwesomeIcon icon = {faArrowRightArrowLeft} /> </Button>
                        </Col>
                        <Col>
                            <Button size='large' >View Profile</Button>
                        </Col>
                        <Col>
                            <Button size='large' >View Profile</Button>
                        </Col>
                        <Col>
                            <Button size='large' >View Profile</Button>
                        </Col>
                    </div>

                </Row>
                <Row justify='center'>
                    <Col>
                        <div className='login-button'>
                            <Button className='gradient-color' size='large' >LOG OUT</Button>
                        </div>
                    </Col>

                </Row>
                <Row justify='center'>
                    <Col>
                        <Checkbox>I understand and agree to the Privacy Policy and Terms of Service</Checkbox>
                    </Col>
                </Row>

            </div>
        </div>
    )
}
