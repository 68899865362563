export const userBasicDetailsComponents = {
    userForm: {
        title: 'User',
        header: 'User Details',

        firstName: 'First Name',
        lastName: 'Last Name',
        middleName: 'Middle Name',
        dateOfBirth: 'Date Of Birth',
        gender: 'Gender',
        country: 'Country',

        reqFirstName: 'Please enter first name',
        reqLastName: 'Please enter last name',
        reqMiddleName: 'Please enter middle name',
        reqDateOfBirth: 'Please enter date of birth',
        reqGender: 'Please enter gender',
        reqCountry: 'Please enter country',
    },
    userGrid: {
        title: 'User',
        header: 'User Details',
        firstName: 'First Name',
        lastName: 'Last Name',
        middleName: 'Middle Name',
        dateOfBirth: 'Date Of Birth',
        gender: 'Gender',
        country: 'Country',
    },
}