export const AddressLabelNames = {
    addressForm: {
        title: 'Address',
        header: 'Address Details',

        doorNo: 'Door No',
        addressLine1: 'Address Line 1',
        addressLine2: 'Address Line 2',
        landmark: 'Landmark',
        state: 'State',
        country: 'Country',
        zipCode: 'ZipCode',

        reqDoorNo: 'please enter door no',
        reqAddressLine1: 'please enter address line 1',
        reqAddressLine2: 'please enter address line 2',
        reqLandmark: 'please enter landmark',
        reqState: 'please enter state',
        reqCountry: 'please enter country',
        reqZipCode: 'please enter zipCode',
    },
}