import React from 'react'
import { Layout, Row, Col, Badge, Menu, Dropdown, Avatar, Popover, Form, Button } from 'antd';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PoweroffOutlined
} from '@ant-design/icons';
import './header-nav-component.css';
import { useAuthState, logout } from '../auth-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBell } from '@fortawesome/free-solid-svg-icons'
import Search from 'antd/lib/transfer/search';

const { Header } = Layout;
interface IProps {
  collapsed: boolean;
  toggle: () => void;
}
export const CommonHeader = (props: IProps) => {
  const { authContext, dispatch } = useAuthState();
  const logoutHandler = () => {
    logout(dispatch);
  }
  return (
    <div style={{ paddingTop: 20 }}>
      <Row justify='space-evenly' align='middle'>
        <Col span={14}>
        </Col>
        <Col span={3} >
          <Button
            style={{ width: '32px' }}
            type="primary"
            icon={<PoweroffOutlined />}
            onClick={() => logoutHandler()}
          />
          <p style={{ color: '#fff' }}>Logout</p>
        </Col>
      </Row>
    </div>
  )
}
