export const kycFormIntlMessages = {
    kycForm: {
        bankName: 'Bank Name',
        reqBankName:'Please Enter Bank Name',
        accountHolderName: 'Account Holder Name',
        reqAccountHolderName:'Please Enter Account Holder Name',
        accountNumber: 'Account Number',
        reqAccountNumber:'Please Enter Account Number',
        swiftCode: 'Swift Code',
        reqSwiftCode:'Please Enter Swift Code',
        iBanNumber: 'IBAN Number',
        reqIBanNumber:'Please Enter IBAN Number',
        currency: 'Currency',
        reqCurrency:'Please Enter Currency',
        address: 'Address',
        reqAddress:'Please Enter Address',
    }
}