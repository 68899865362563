import { ActionTypes } from "./action-types";
import { AuthActions } from "./reducers";

const ROOT_URL = 'https://secret-hamlet-03431.herokuapp.com';

export const loginUser = async (dispatch: React.Dispatch<AuthActions>, loginPayload: any) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload),
    };

    try {
        dispatch({ type: ActionTypes.REQUEST_LOGIN });
        // let response = await fetch(`${ROOT_URL}/login`, requestOptions);
        // let data = await response.json();

        if (loginPayload.userName == 'admin' && loginPayload.password == 'admin') {
            const data = {
                loading: false,
                isAuthenticated: true,
                user: {
                    userName: 'Admin'
                },
                token: 'token',
                keycloak: 'keyCloak',
                defaultPlant: 'defaultPlant',
                allocatedPlants: ['SAI'],
                menuAccessObject: [],
                errorMessage: null
            }
            dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
            localStorage.setItem('currentUser', JSON.stringify(data));
            return data
        }
        const errorMessage: any = { errorMessage: 'Error message' };
        dispatch({ type: ActionTypes.LOGIN_ERROR, payload: errorMessage });
        return;
    } catch (error: any) {
        const errorMessage: any = { errorMessage: error.message };
        dispatch({ type: ActionTypes.LOGIN_ERROR, payload: errorMessage });
    }
}

export const logout = async (dispatch: React.Dispatch<AuthActions>) => {
    dispatch({ type: ActionTypes.LOGOUT });
    localStorage.removeItem('userData');
}

export const loadUser = async (dispatch: React.Dispatch<AuthActions>) => {
    const userData = localStorage.getItem('userData');
    if (!userData) {
        
    } else {
        let data = JSON.parse(userData);
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
        localStorage.setItem('userData', JSON.stringify(data));
    }


};