import React from 'react';
import ReactDOM from 'react-dom/client';
import './antd.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import {
   BrowserRouter,HashRouter
} from "react-router-dom";
import { flattenMessages } from './common/uitls';
import { AuthProvider } from './components/common';
import { messages } from './common/intl-message';
export const intlMessages: any = {
  en: messages
};
export const language = navigator.language.split(/[-_]/)[0];
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <AuthProvider>
      <IntlProvider locale={language} messages={flattenMessages(intlMessages[language])}>
          <App />
      </IntlProvider>
    </AuthProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// import React from 'react';
// import ReactDOM from 'react-dom';
// import './antd.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { IntlProvider } from 'react-intl';
// import {
//   BrowserRouter
// } from "react-router-dom";
// import { flattenMessages } from './common/uitls';
// import { AuthProvider } from './components/common';
// import { messages } from './common/intl-message';
// export const intlMessages: any = {
//   en: messages
// };
// export const language = navigator.language.split(/[-_]/)[0];
// // const root = ReactDOM.createRoot(
// //   document.getElementById('root') as HTMLElement
// // );
// ReactDOM.hydrate(
//   <BrowserRouter>
//     <AuthProvider>
//       <IntlProvider locale={language} messages={flattenMessages(intlMessages[language])}>
//         <App />
//       </IntlProvider>
//     </AuthProvider>
//   </BrowserRouter>,
//   document.getElementById('root') as HTMLElement
// );
// // root.render(
// //   <BrowserRouter>
// //     <AuthProvider>
// //       <IntlProvider locale={language} messages={flattenMessages(intlMessages[language])}>
// //           <App />
// //       </IntlProvider>
// //     </AuthProvider>
// //   </BrowserRouter>
// // );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
