import { AxiosRequestConfig } from "axios";
import { CommonAxiosServiceMDM } from "./common-axios-service-mdm";



export class AuthService extends CommonAxiosServiceMDM {
    private getURLwithMainEndPoint(childUrl: string) {
        return '/auth/' + childUrl;
    }
    async registerUser(createDto: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('register'), createDto, config);
    }

    async login(createDto: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('login'), createDto, config);
    }

    async updateProfile(createDto: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('updateProfile'), createDto, config);
    }

    async checkIfUserExist(serialNumber: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('checkIfUserExist'), serialNumber, config);
    }

    async addProfilePhoto(profileDto: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('addProfilePhoto'), profileDto, config);
    }

    async getMetaTagsData(serialNumber: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('getMetaTagsData'), serialNumber, config);
    }
    
    async sendForgotPasswordMail(req: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('sendForgotPasswordMail'), req,config);
    }

    async verifyOtp(req: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('verifyOtp'), req,config);
    }4

    async updatePassword(req: any, config?: AxiosRequestConfig): Promise<any> {
        return await this.axiosPostCall(this.getURLwithMainEndPoint('updatePassword'), req,config);
    }

}
