import React from 'react';
import { Form, Input, Button } from 'antd';
import './landing-page.css';
import DataLynxLogo from '../../images/DataLynx-Logo.png'
import { loginUser, useAuthState } from '../auth-context';
import { useNavigate } from 'react-router-dom';


export const LandingPage = () => {
    const { dispatch } = useAuthState();
    const navigate = useNavigate();


    const handleLogin = () => {
        navigate('/login')
    }
    const handleSignUp = () => {
        navigate('/signUp')
    }
    return (
        <div>
            <div className="bg-img">
                <div className="login">
                    <div className="imgcontainer">
                        <object style={{width:'100%'}} data={DataLynxLogo}></object>
                        <p style={{ marginTop: '2em' }}>Your digital gateway to smart & contactless sharing</p>

                    </div>
                    <div className='signup-button'>
                        <h3>It's my first time here</h3>
                        <Button className='gradient-color' size='large' onClick={handleSignUp}>SIGN UP</Button>
                    </div>
                    <div className="login-button">
                        <h3>Back to sharpen my profile</h3>
                        <Button className='gradient-color' size='large' onClick={handleLogin}>LOGIN</Button>
                    </div>

                </div>
                <div className='footer'>
                    <p>
                        Haven't got your own Datalynx card?
                    </p>
                    <p>
                        Click here!
                    </p>
                </div>
            </div></div>
    )
}

export default LandingPage;