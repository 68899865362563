import { Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AuthService } from '../../../services/auth/auth.service';
import { useAuthState } from '../auth-context';
import { ActionTypes } from '../auth-context/action-types';
import ProfileView from '../profile-view/profile-view';
import Scanner from '../scanner';

const ShareProfile = () => {
    const service = new AuthService();
    const { serialNumber } = useParams<any>()
    const { dispatch, authContext } = useAuthState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        service.checkIfUserExist({ serialNumber: serialNumber }).then((res) => {
            if (res.status && !authContext.isAuthenticated) {
                const data = {
                    loading: false,
                    isAuthenticated: false,
                    user: {
                        userName: res.data.firstName,
                        profileData: res.data.profiles[0],
                        uniqueId: res.data.uniqueId
                    },
                    token: 'token',
                    keycloak: 'keyCloak',
                    defaultPlant: 'defaultPlant',
                    allocatedPlants: ['SAI'],
                    menuAccessObject: [],
                    errorMessage: null
                }
                dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(err => {
            setLoading(false)
        });
    }, []);


    return (
        <div><ProfileView />
            <Modal visible={loading} footer={null} closable={false} centered>
                <Scanner />
            </Modal>
        </div>
    )
}

export default ShareProfile;