import React, { useState } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import './enter-otp.css'
import OtpInput from './otp-input'
import { AuthService } from '../../../services/auth/auth.service'
import { AlertMessages } from '../notifications'
import OTPInput, { ResendOTP } from "otp-input-react";

export interface EnterOtpPopUpProps {
  email: string
  setVerificationStatus : (value) => void
}

export default function EnterOtpPopUp(props: EnterOtpPopUpProps) {
  const [otp, setOtp] = useState('');
  const service = new AuthService();

  const verifyOtp = () => {
    const req = { email: props.email, otp: Number(otp), time: Date.now() }
    service.verifyOtp(req).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage("Verified")
        props.setVerificationStatus(true);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    })
  }
  const renderButton = buttonProps => {
    return <>
      <br />
      <Button className='gradient-color' size='large' style={{ height: '40px', borderRadius: '16px' }} {...buttonProps} >
        Resend
      </Button>
    </>
  };

  const renderTime = remainingTime => {
    return <span>{remainingTime} seconds remaining</span>;
  };
  return (
    <div>
      <div className="bg-imge">


        <Row justify='start'>
          <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
            <h1>We sent a code to your email</h1>
            <p>Enter the 6-digit verification code sent to {props.email}</p>
          </Col>
        </Row>
        <Row justify='center' style={{ paddingTop: '30px', paddingBottom: '10px' }}>
          <Col >
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure
            />
          </Col>
        </Row>
        {/* <Row justify='center'>
          <ResendOTP renderTime={renderTime} renderBtton={renderButton} handelResendClick={() => console.log("Resend clicked")} />
        </Row> */}
        <Row justify='center'>
          <Col>
            {/* <div className="gradient-color"> */}
            <Button className='gradient-color' size='large' style={{ height: '40px', borderRadius: '16px' }} onClick={() =>
              verifyOtp()}>
              Submit
            </Button>
            {/* </div> */}
          </Col>
        </Row>
        <Row justify='start'>
          <p >If you don't see the email in your inbox, check your spam folder.</p>

        </Row>

      </div>
    </div>
  )
}
