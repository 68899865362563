import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Row, Col, Upload, message, Tooltip, Tabs, Card, Select, DatePicker, Modal, Slider, Menu } from 'antd';
import { PoweroffOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { loginUser, logout, useAuthState } from '../auth-context';
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import ImgCrop from 'antd-img-crop';
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { debounce } from 'lodash';
import ImageAvatar from '../../images/DataLynx-Logo.png';
import './profile-view.css';
import VCard from 'vcard-creator';
import { faPenToSquare, faQrcode, faCircleUser, faPhone, faShareNodes, faEnvelope, faBriefcase, faBuilding, faLink, faLocationDot, faAt, faBookmark, faBook, faGraduationCap, faArrowRight, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faInstagram, faFacebook, faTelegram, faWhatsapp, faSquareYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import { ProfileDto } from './profile-create.dto';
import { saveAs } from 'file-saver';
import ShareModel from './share-model';
import './share-model.css';
import { AuthService } from '../../../services/auth/auth.service';
import Scanner from '../scanner';
import getCroppedImg from './crop-image';
import { countryCodes } from './country-code';
import { getMetaTags } from './met-tags';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ActionTypes } from '../auth-context/action-types';
import { AlertMessages } from '../notifications';


const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;
const fileUploadPath = window[`_env_`]['APP_MDM_SERVICE_URL'];


export const ProfileView = () => {
    const { dispatch, authContext } = useAuthState();
    const [fileList, setFileList] = useState([]);

    const getDefaultImagePath = () => {
        let path = ImageAvatar;
        if (authContext?.user?.profileData?.filePath && fileList.length == 0) {
            path = fileUploadPath + '/' + authContext?.user?.profileData.filePath.slice(7);
        }
        return path;
    }
    const [imageUrl, setImageUrl] = useState<any>(getDefaultImagePath());
    const [showModal, setShowModal] = useState(false);
    const [qrCode, setQrCode] = useState(false);
    const qrRef = useRef<HTMLDivElement>(null);
    let url = document.location.href
    url = url.replace('profile', 'shareProfile');
    const shareDetails = { url, title: '', text: '' };
    const [initialValues, setInitialValues] = useState<ProfileDto>(undefined);
    const [formRef] = Form.useForm();
    const [imageEdit, setImageEdit] = useState(false);
    const [imageResize, setImageResize] = useState(false);
    const [editData, setEditData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [shareData, setShareData] = useState(shareDetails);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageUrl,
                croppedAreaPixels,
                rotation
            )
            setImageUrl(croppedImage);
            setImageEdit(false);
            setImageResize(false);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setImageUrl(null)
    }, [])


    const service = new AuthService();
    const navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const getSideMenu = () => {
        return (
            <div className={'side-menu'}>
                <Menu
                    style={{ width: '40px' }}
                    mode="horizontal"
                    inlineCollapsed={collapsed}
                    expandIcon={<RightCircleOutlined />}
                >
                    <Menu.Item icon={<FontAwesomeIcon onClick={() => setQrCode(true)} icon={faQrcode} size='lg'
                    />} />
                    <Menu.Item icon={<FontAwesomeIcon icon={faPenToSquare} size='lg' onClick={editAll} />} />
                    <Menu.Item icon={<FontAwesomeIcon icon={faPowerOff} size='lg' onClick={() => logoutHandler()} />} />

                </Menu>
            </div>
        )
    }


    const renderImage = () => {
        return imageEdit ? (<ImgCrop zoom={true} rotate shape='round'>
            <Upload
                listType="picture-card"
                customRequest={onFileChange}
                fileList={fileList}
                beforeUpload={beforeUpload}
                maxCount={1}
                multiple={false}
                showUploadList={false}
                accept="image/*;capture=camera"
            //capture='environment'
            >
                +upload
            </Upload>
        </ImgCrop>) : <img src={imageUrl} />
    }

    useEffect(() => {
        getImageData()
    }, [fileList]);

    useEffect(() => {
        setInitialValues({ name: `${authContext?.user?.profileData?.firstName} ${authContext?.user?.profileData?.lastName}`, ...authContext.user.profileData });
        setImageUrl(getDefaultImagePath())
        formRef.resetFields();
    }, [authContext]);

    useEffect(() => {
        //document.head.innerHTML += getMetaTags(initialValues, authContext?.user?.uniqueId)
        formRef.resetFields();
        //formRef.setFieldsValue(initialValues);
    }, [initialValues]);

    useEffect(() => {
        saveProfilePhoto();
    }, [imageUrl]);


    const saveProfilePhoto = () => {
        if (fileList.length) {
            const dataToSendToServer = {
                filesData: [{
                    ...fileList[0],
                    base64Url: imageUrl,
                    fileDescription: fileList[0].name,
                    name: fileList[0].name
                }],
                id: authContext?.user?.profileData?.id
            };
            setLoading(true);
            service.addProfilePhoto(dataToSendToServer).then(res => {
                if (res) {
                    setLoading(false);
                    setFileList([])
                    const data = JSON.parse(JSON.stringify(authContext));
                    data.user.profileData.filePath = res.data.filePath;
                    dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
                    localStorage.removeItem('userData');
                    localStorage.setItem('userData', JSON.stringify(data));
                    message.success('Image uploaded successfully')
                } else {
                    setLoading(false)

                }
            }).catch((err) => {
                setLoading(false)
            });
        }
    }


    const getImageData = () => {
        if (fileList.length === 0) {
            return '';
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(fileList[0]);
            reader.onload = () => {
                setImageUrl(reader.result);
            }
        }
    }

    const onFileChange = (data) => {
        setFileList([data.file]);
        setImageEdit(false);
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        //const isLt2M = file.size / 1024 < 100;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    }

    const imageEditOnClickHandler = () => {
        setImageEdit(prevState => !prevState);
    }

    const imageResizeOnClickHandler = () => {
        setImageEdit(true)
        setImageResize(true);
    }

    const editAll = () => {
        setEditData(prev => !prev)
    }

    const saveContact = () => {
        const myVCard = new VCard();
        // Some variables
        const lastname = ''
        const firstname = initialValues.name
        const additional = ''
        const prefix = ''
        const suffix = ''

        myVCard
            // Add personal data
            .addName(lastname, firstname, additional, prefix, suffix)
            // Add work data
            .addCompany(initialValues?.company ? initialValues?.company : '')
            .addJobtitle(initialValues?.jobTitle ? initialValues?.jobTitle : '')
            .addRole(initialValues?.bio ? initialValues?.bio : '')
            .addEmail(initialValues?.email ? initialValues?.email : '')
            .addPhoneNumber(initialValues?.phoneNo ? initialValues?.countryCode + initialValues?.phoneNo : '', 'HOME')
            .addPhoneNumber(initialValues?.officeNo ? initialValues?.officeCountryCode + initialValues?.officeNo : '', 'WORK')
            .addAddress(firstname, lastname, initialValues?.address ? initialValues?.address : '')
            .addURL(initialValues?.website ? initialValues?.website : '')
            .addPhoto(imageUrl)

        //myVCard.addLogo()
        const file = new Blob(
            [
                myVCard.toString()
            ],
            { type: "text/vcard;charset=utf-8" }
        );

        saveAs(
            file,
            `${initialValues.name}.vcf`,
            true
        );

    }

    const handleSharing = async () => {
        let shareDetailsLocal = { ...shareDetails }
        shareDetailsLocal.url = "https://digionecard.com/#/shareProfile/" + authContext?.user?.uniqueId;
        if (navigator.share) {
            try {
                await navigator
                    .share(shareDetailsLocal)
                    .then(() =>
                        console.log("Hooray! Your content was shared to tha world")
                    );
            } catch (error) {
                console.log(`Oops! I couldn't share to the world because: ${error}`);
            }
        } else {
            setShareData(shareDetailsLocal);
            // fallback code
            setShowModal(true);
            console.log(
                "Web share is currently not supported on this browser. Please provide a callback"
            );
        }
    };

    const onValuesChangeHandler = (changedValues: any, allValues: any) => {
        const profileData = JSON.parse(JSON.stringify({ ...authContext.user.profileData, ...allValues, ...changedValues }));
        //Object.assign(profileData, allValues);
        if (profileData?.file) {
            delete profileData.file
        }
        delayedQuery(profileData);
    }

    const delayedQuery = useCallback(
        debounce((changedData: any) => sendQuery(changedData), 500), // (*)
        []
    );

    const sendQuery = useCallback(
        async (changedData: any): Promise<any> => {
            console.log(changedData, 'changedData')
            if (changedData?.phoneNo?.charAt(0) == '+') {
                //if (changedData.phoneNo.substring(0, 3) !== changedData.countryCode)
                changedData.phoneNo = changedData.phoneNo.slice(3)
            } else {
                const phoneNo = changedData.phoneNo ? changedData.phoneNo : '';
                changedData.phoneNo = phoneNo;
            }

            if (changedData?.officeNo?.charAt(0) == '+') {
                //if (changedData.officeNo.substring(0, 3) !== changedData.officeCountryCode)
                changedData.officeNo = changedData.officeNo.slice(3)
            } else {
                const officeNo = changedData.officeNo ? changedData.officeNo : '';
                changedData.officeNo = officeNo;
            }

            try {
                await service.updateProfile(changedData).then(res => {
                    const data = JSON.parse(localStorage.getItem('userData'));
                    data.user.profileData = { ...data.user.profileData, ...changedData };
                    // dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
                    localStorage.removeItem('userData');
                    localStorage.setItem('userData', JSON.stringify(data));
                })
            } catch (error) {
                console.log('error')
            }
        },
        []
    );

    const saveData = (values) => {
        console.log('values', values)
        service.updateProfile(values).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage('Profile updated')
            }
            const data = JSON.parse(localStorage.getItem('userData'));
            data.user.profileData = { ...data.user.profileData, ...values };
            // dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
            localStorage.removeItem('userData');
            localStorage.setItem('userData', JSON.stringify(data));
        })

    }



    const downLoadQR = () => {
        const filename = `${authContext.user.userName}'s QR Code.png`;
        let canvas = qrRef.current.querySelector("canvas");
        let image = canvas.toDataURL("image/png");
        let anchor = document.createElement("a");
        anchor.href = image;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    const getFormValue = (value: string) => {
        if (formRef.getFieldValue(value)) { console.log('form value e: ', formRef.getFieldValue(value)); return formRef.getFieldValue(value); }
        return initialValues[value];
    }
    const selectBefore = (inputName, initialValue) => {
        return <Form.Item
            name={inputName}
            noStyle
            initialValue={initialValue ? initialValue : "+60"}
        >
            <Select disabled={!editData} >
                {
                    countryCodes.map(code => {
                        return <Option value={code.dial_code}>{code.code + '' + code.dial_code}</Option>
                    })
                }
            </Select>
        </Form.Item>

    };
    const logoutHandler = () => {
        logout(dispatch);
    }
    return (
        <div className="bg-img">
            {authContext.isAuthenticated && <div style={{ paddingTop: 20 }}>

                {/* <Row justify='end' style={{display:'flex',position:'absolute',left:'86%'}}>
                    <Col>
                        {getSideMenu()}
                    </Col>
                </Row> */}
                <Row justify='center' >
                    <Col className='fa-icon' style={{ alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip placement="top" title='QR'>
                            <FontAwesomeIcon onClick={() => setQrCode(true)} icon={faQrcode} size='lg' />
                            <p style={{ color: '#fff' }}>QR</p>
                        </Tooltip>
                    </Col>
                    <Col className='fa-icon' style={{ alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip placement="top" title='Edit'>
                            <FontAwesomeIcon icon={faPenToSquare} size='lg' onClick={editAll} />
                            <p style={{ color: '#fff' }}>Edit</p>
                        </Tooltip>
                    </Col>
                    <Col className='fa-icon' style={{ alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip placement="top" title='Edit'>
                            <FontAwesomeIcon icon={faPowerOff} size='lg' onClick={() => logoutHandler()} />
                            <p style={{ color: '#fff' }}>Logout</p>
                        </Tooltip>
                    </Col>
                </Row>
            </div>}
            <Form form={formRef} initialValues={initialValues} onValuesChange={onValuesChangeHandler} autoComplete='off' onFinish={saveData}>
                <Form.Item name='id' style={{ display: "none" }}  ><Input hidden /></Form.Item>
                <Row justify='center' style={{ paddingTop: '50px' }}>
                    <div className="profile-picture">
                        {renderImage()}
                    </div>
                </Row>
                {/* <Row justify='center' style={{ paddingTop: '50px' }}>
                    <Col>
                    </Col>
                    <Col>
                        <div className="profile-picture">
                            {renderImage()}
                        </div>
                    </Col>
                    <Col>
                        {authContext.isAuthenticated && <Row justify='center' align='middle' gutter={[16, 24]}>
                            <Col className='fa-icon' span={24}>
                                <Tooltip placement="top" title='QR'>
                                    <FontAwesomeIcon onClick={() => setQrCode(true)} icon={faQrcode} size='lg' />
                                </Tooltip>
                            </Col>
                            <Col className='fa-icon' span={24}>
                                <Tooltip placement="top" title='Edit'>
                                    <FontAwesomeIcon icon={faPenToSquare} size='lg' onClick={editAll} />
                                </Tooltip>
                            </Col>
                        </Row>}
                    </Col>
                </Row> */}
                <div className='profile-view'>
                    {editData &&
                        <div className="edit-resize-buttons">
                            <Row justify='center' style={{ padding: '10px' }} >
                                <Col >
                                    <Button size='small' shape='round' onClick={imageEditOnClickHandler}>UPLOAD</Button>
                                </Col>
                                <Col>
                                    <Button size='small' shape='round' onClick={imageResizeOnClickHandler}>RESIZE</Button>
                                </Col>
                            </Row>
                        </div>}
                    <Row justify='center' className='abc'>
                        <Col className='input-field'>
                            {editData ? <Form.Item
                                name="name"
                            // rules={[
                            //     {
                            //         required: false,
                            //         message: 'Your Name',
                            //     },
                            // ]}
                            >
                                <Input prefix={<FontAwesomeIcon icon={faCircleUser} />} placeholder={'Your Name'} disabled={!editData} />
                            </Form.Item> :
                                <h4 style={{ marginTop: '14px', color: '#fff', fontSize: '20px' }}>{initialValues?.name}</h4>}
                        </Col>
                    </Row>
                    <Row justify='center' className='abc'>
                        <Col className='input-field'>
                            {editData ? <Form.Item
                                name="bio"
                            // rules={[
                            //     {
                            //         required: false,
                            //         message: 'Your Bio',
                            //     },
                            // ]}
                            >
                                <Input.TextArea style={{ width: '30vh' }} rows={3} placeholder={'Your Bio'} disabled={!editData} />
                            </Form.Item> :
                                <h4 style={{ marginTop: '14px', color: '#fff', fontSize: '12px', textAlign: 'center', maxWidth: '200px',border:'1px solid #00C564',padding:'20px 85px',borderRadius:'5px' }}>
                                    {initialValues?.bio}
                                </h4>
                            }
                        </Col>

                    </Row>
                    <Row justify='center' className='icon-bottons-row' >
                        <Col className='icon-bottons'>
                            <Button href={`tel:+${initialValues?.phoneNo}`} icon={<FontAwesomeIcon icon={faPhone} style={{}} />}></Button>
                        </Col>
                        <Col className='icon-bottons'>
                            <Button href={`mailto:${initialValues?.email}`} icon={<FontAwesomeIcon icon={faEnvelope} style={{}} />}></Button>
                        </Col>
                        <Col className='icon-bottons'>
                            <Button onClick={handleSharing} icon={<FontAwesomeIcon icon={faShareNodes} style={{}} />}></Button>
                        </Col>
                    </Row>
                </div>
                <Row justify='center'>

                    <div className="bottom-buttons">
                        <div className="aboount-and-linkstabs">
                            <Tabs tabPosition='top' type={'line'} centered>
                                <TabPane key={'about'} tab={<Button size='small' shape='round'>ABOUT</Button>}>
                                    <Card className='about-tab-card'>
                                        <div className="about-tab-inputs">
                                            <Col >
                                                <h3 style={{ color: '#fff', textAlign: 'center' }}>--- ABOUT ---</h3>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="jobTitle"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Job title',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faBriefcase} fontSize='16px' />} placeholder={'Job title'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="company"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Company',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faBuilding} fontSize='16px' />} placeholder={'Company'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="email"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Email',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faAt} fontSize='16px' />} placeholder={'Email'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="website"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Website',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faLink} fontSize='16px' />} placeholder={'Website'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="officeNo"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Office No',
                                                //     },
                                                // ]}
                                                >
                                                    <Input style={{ width: '97%' }} addonBefore={selectBefore('officeCountryCode', initialValues?.officeCountryCode)} prefix={<FontAwesomeIcon icon={faPhone} fontSize='16px' />} placeholder={'Enter with out zero'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="address"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Address',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faLocationDot} fontSize='16px' />} placeholder={'Address'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>

                                            <Col >
                                                <h3 style={{ color: '#fff', textAlign: 'center' }}>--- CONTACT---</h3>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="email"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Email',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faAt} fontSize='16px' />} placeholder={'Email'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="phoneNo"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Phone No',
                                                //     },
                                                // ]}
                                                >
                                                    <Input style={{ width: '97%' }} addonBefore={selectBefore('countryCode', initialValues?.countryCode)} prefix={<FontAwesomeIcon icon={faPhone} fontSize='16px' />} placeholder={'Enter with out zero'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                        </div>
                                        <Col className='input-field'>
                                            <Button className='gradient-color' size='large' style={{ width: '100%', marginTop: '5px' }}
                                                onClick={saveContact}>Import contact</Button>
                                        </Col>
                                    </Card>

                                </TabPane>
                                <TabPane key={'links'} tab={<Button size='small' shape='round'>Link</Button>}>
                                    <Card className='link-tab-card'>
                                        <div className="link-tab-inputs">
                                            <Col >
                                                <h3 style={{ color: '#fff', textAlign: 'center' }}>--- ACADEMIC ---</h3>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="courseTitle"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Course Title',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faBookmark} fontSize='16px' />} placeholder={'Course Title'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="specialization"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Specialization',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faBook} fontSize='16px' />} placeholder={'Specialization'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field'>
                                                <Form.Item
                                                    name="schoolName"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'School Name',
                                                //     },
                                                // ]}
                                                >
                                                    <Input prefix={<FontAwesomeIcon icon={faGraduationCap} fontSize='16px' />} placeholder={'University'} disabled={!editData} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='input-field' >
                                                <Form.Item
                                                    name="academic"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'academic',
                                                //     },
                                                // ]}
                                                >
                                                    {!editData ? <p color='white'>{initialValues?.academic && Array.isArray(initialValues?.academic) ? <p>{`${moment(initialValues?.academic[0]).format('YYYY-MM-DD')} - ${moment(initialValues?.academic[1]).format('YYYY-MM-DD')}`}</p> : <></>}</p> : <RangePicker />}
                                                </Form.Item>
                                            </Col>
                                            <Col >
                                                <h3 style={{ color: '#fff', textAlign: 'center' }}>--- SOCIAL---</h3>
                                            </Col>
                                            <Row gutter={24} justify='center'>
                                                <Col className='input-field' span={editData ? 22 : 6}>
                                                    {editData ? <Form.Item
                                                        name="whatsapp"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Whatsapp',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <Input style={{ width: '95%' }} prefix={<FontAwesomeIcon icon={faWhatsapp as IconProp} fontSize='16px' />} placeholder={'Whatsapp'} disabled={!editData} />

                                                    </Form.Item> :

                                                        <div style={{ textAlign: 'center' }}>
                                                            <Button size='large' style={{ height: '40px', borderRadius: '16px', width: '70%', background: '#25D366' }} icon={<FontAwesomeIcon icon={faWhatsapp as IconProp} fontSize='16px' />} onClick={() => {
                                                                if (!formRef.getFieldValue('whatsapp')) {
                                                                    AlertMessages.getWarningMessage('There is no link added')
                                                                    return true;
                                                                }
                                                                if (formRef.getFieldValue('whatsapp')?.includes('http')) {
                                                                    window.open(formRef.getFieldValue('whatsapp'), '_blank')
                                                                } else {
                                                                    window.open(`https://wa.me/${formRef.getFieldValue('whatsapp')}`, '_blank')
                                                                }
                                                            }}>
                                                            </Button>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className='input-field' span={editData ? 22 : 6}  >
                                                    {editData ? <Form.Item
                                                        name="instagram"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Instagram',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <Input style={{ width: '95%' }} prefix={<FontAwesomeIcon icon={faInstagram as IconProp} fontSize='16px' />} placeholder={'Instagram'} disabled={!editData} />

                                                    </Form.Item> :
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Button size='large' style={{ height: '40px', borderRadius: '16px', width: '70%', background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)" }} icon={<FontAwesomeIcon icon={faInstagram as IconProp} fontSize='16px' />} onClick={() => {
                                                                if (!formRef.getFieldValue('instagram')) {
                                                                    AlertMessages.getWarningMessage('There is no link added')
                                                                    return true;
                                                                }
                                                                if (formRef.getFieldValue('instagram')?.includes('http')) {
                                                                    window.open(formRef.getFieldValue('instagram'), '_blank')
                                                                } else {
                                                                    window.open(`https://www.instagram.com/${formRef.getFieldValue('instagram')}`, '_blank')
                                                                }
                                                            }}>
                                                            </Button>
                                                        </div>
                                                    }

                                                </Col>

                                                <Col className='input-field' span={editData ? 22 : 6}>
                                                    {editData ? <Form.Item
                                                        name="facebook"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Facebook',
                                                    //     },
                                                    // ]}
                                                    >

                                                        <Input style={{ width: '95%' }} prefix={<FontAwesomeIcon icon={faFacebook as IconProp} fontSize='16px' />} placeholder={'Facebook'} disabled={!editData} />

                                                    </Form.Item> :
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Button size='large' style={{ height: '40px', borderRadius: '16px', width: '70%', background: '#4267B2' }} icon={<FontAwesomeIcon icon={faFacebook as IconProp} fontSize='16px' />} onClick={() => {
                                                                if (!formRef.getFieldValue('facebook')) {
                                                                    AlertMessages.getWarningMessage('There is no link added')
                                                                    return true;
                                                                }
                                                                if (formRef.getFieldValue('facebook')?.includes('http')) {
                                                                    window.open(formRef.getFieldValue('facebook'), '_blank')
                                                                } else {
                                                                    window.open(`https://www.facebook.com/${formRef.getFieldValue('facebook')}`, '_blank')
                                                                }
                                                            }}>
                                                            </Button>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className='input-field' span={editData ? 22 : 6}>
                                                    {editData ? <Form.Item
                                                        name="telegram"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'Telegram',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <Input style={{ width: '95%' }} prefix={<FontAwesomeIcon icon={faTelegram as IconProp} fontSize='16px' />} placeholder={'Telegram'} disabled={!editData} />

                                                    </Form.Item> :
                                                        <div style={{ textAlign: 'center', padding: '5px' }}>
                                                            <Button size='large' style={{ height: '40px', borderRadius: '16px', width: '70%', background: '#0088CC' }} icon={<FontAwesomeIcon icon={faTelegram as IconProp} fontSize='16px' />} onClick={() => {
                                                                if (!formRef.getFieldValue('telegram')) {
                                                                    AlertMessages.getWarningMessage('There is no link added')
                                                                    return true;
                                                                }
                                                                if (formRef.getFieldValue('telegram')?.includes('http')) {
                                                                    window.open(formRef.getFieldValue('telegram'), '_blank')
                                                                } else {
                                                                    window.open(`https://t.me/${formRef.getFieldValue('telegram')}`, '_blank')
                                                                }
                                                            }}>
                                                            </Button>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className='input-field' span={editData ? 22 : 6}>
                                                    {editData ? <Form.Item
                                                        name="tiktok"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'LinkedIn',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <Input style={{ width: '95%' }} prefix={<FontAwesomeIcon icon={faLinkedinIn as IconProp} fontSize='16px' />} placeholder={'LinkedIn'} disabled={!editData} />

                                                    </Form.Item> :
                                                        <div style={{ textAlign: 'center', padding: '5px' }}>
                                                            <Button size='large' style={{ height: '40px', borderRadius: '16px', width: '70%', background: '#0a66c2' }} icon={<FontAwesomeIcon icon={faLinkedinIn as IconProp} fontSize='16px' />} onClick={() => {
                                                                if (!formRef.getFieldValue('tiktok')) {
                                                                    AlertMessages.getWarningMessage('There is no link added')
                                                                    return true;
                                                                }
                                                                if (formRef.getFieldValue('tiktok')?.includes('http')) {
                                                                    window.open(formRef.getFieldValue('tiktok'), '_blank')
                                                                } else {
                                                                    window.open(`https://www.linkedin.com/in/${formRef.getFieldValue('tiktok')}`, '_blank')
                                                                }

                                                            }}>
                                                            </Button>
                                                        </div>
                                                    }
                                                </Col>

                                                <Col className='input-field' span={editData ? 22 : 6}>
                                                    {editData ? <Form.Item
                                                        name="youtube"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: 'LinkedIn',
                                                    //     },
                                                    // ]}
                                                    >
                                                        <Input style={{ width: '95%' }} prefix={<FontAwesomeIcon icon={faSquareYoutube as IconProp} fontSize='16px' />} placeholder={'Youtube'} disabled={!editData} />

                                                    </Form.Item> :
                                                        <div style={{ textAlign: 'center', padding: '5px' }}>
                                                            <Button size='large' style={{ height: '40px', borderRadius: '16px', width: '70%', background: '#c00' }} icon={<FontAwesomeIcon icon={faSquareYoutube as IconProp} fontSize='16px' />} onClick={() => {
                                                                if (!formRef.getFieldValue('youtube')) {
                                                                    AlertMessages.getWarningMessage('There is no link added')
                                                                    return true;
                                                                }
                                                                if (formRef.getFieldValue('youtube')?.includes('http')) {
                                                                    window.open(formRef.getFieldValue('youtube'), '_blank')
                                                                } else {
                                                                    window.open(`https://www.youtube.com/${formRef.getFieldValue('youtube')}`, '_blank')
                                                                }

                                                            }}>
                                                            </Button>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </TabPane>

                            </Tabs>
                        </div>
                    </div>

                </Row>
                {/* <Row justify='space-around' gutter={24} style={{paddingTop:'11px'}} >
                    <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 6}} xl={{ span: 6}} >
                        <img src={DataLynxLogo} style={{width:'100%',height:'170%'}} />
                    </Col >
                </Row> */}
                {authContext.isAuthenticated && editData && <Row justify='center'>
                    <Col className='input-field'>
                        <Button className='gradient-colo' style={{ width: '100%', marginTop: '5px' }}
                            htmlType='submit'>Save</Button>
                    </Col>
                </Row>}
                <Row justify='center' style={{ paddingTop: '40px' }} >
                    <p style={{ color: 'white' }}><b>To get your NFC card today </b> </p>
                </Row>
                <Row justify='center'>
                    <p style={{ color: 'white' }}><b>Contact us </b> </p>
                </Row>

                <Row justify='center' style={{ paddingBottom: '40px' }}>

                    <div style={{ textAlign: 'center' }}>
                        <Button size='large' style={{ background: '#4267B2' }} icon={<FontAwesomeIcon icon={faFacebook as IconProp} />} onClick={() => {
                            window.open(`https://www.facebook.com/Digionecardofficial/`, '_blank')
                        }}>
                        </Button>
                    </div>

                    <div style={{ textAlign: 'center', marginLeft: '20px' }}>
                        <Button size='large' style={{ background: '#25D366' }} icon={<FontAwesomeIcon icon={faWhatsapp as IconProp} />} onClick={() => {
                            window.open(`https://wa.me/60174006117?text=${encodeURIComponent('Hello!')}`, '_blank')
                        }}>
                        </Button>
                    </div>
                </Row>
                <Row justify='center' style={{ paddingBottom: '40px' }}>
                        <a href='https://www.digionecard.com/#/login'>Login here</a>
                </Row>
                {/* <Row justify='center'>

                    <p>
                        Design  ©  {new Date().getUTCFullYear()} Developed by TT
                    </p>

                </Row> */}
            </Form>
            <Modal
                key='imageResize'
                visible={imageResize}
                //title="Download your digital profile's QR code below for convenient virtual sharing"
                centered
                width={1000}
                footer={[
                    <Button key="back" onClick={() => setImageResize(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => showCroppedImage()}>
                        Save
                    </Button>
                ]}
                onCancel={() => setImageResize(false)}
                onOk={() => setImageResize(false)}
                closable
            >
                <Card style={{ height: '50vh' }}>

                    <Cropper
                        cropShape='round'
                        image={imageUrl}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        aspect={4 / 3}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </Card>

                <div className="controls">
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(zoom) => setZoom(Number(zoom))}
                    />
                    <Slider
                        value={rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        onChange={(rotation) => setRotation(rotation)}
                    />
                </div>

            </Modal>
            <Modal
                key='share-modal'
                visible={showModal}
                onCancel={() => setShowModal(false)}
                onOk={() => setShowModal(false)}
                closable
            >
                <ShareModel
                    handleClose={setShowModal}
                    shareData={shareData}
                    modalVisible={showModal}
                />
            </Modal>

            <Modal
                key='qr-code'
                visible={qrCode}
                title="Download your digital profile's QR code below for convenient virtual sharing"
                centered
                width={1000}
                footer={
                    <Row justify='space-between'>
                        <Col>
                            <Button key="back" onClick={() => setQrCode(false)}>
                                Close
                            </Button>
                        </Col>
                        <Col>
                            <Button key="submit" type="primary" onClick={() => downLoadQR()}>
                                Download
                            </Button>
                        </Col>
                    </Row>
                }
                onCancel={() => setQrCode(false)}
                onOk={() => setQrCode(false)}
                closable
            >
                <div ref={qrRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <QRCodeCanvas
                        id='qrCode'
                        width={'100%'}
                        value={"https://digionecard.com/#/shareProfile/" + authContext?.user?.uniqueId}
                        size={300}
                        level={"H"}
                    /></div>

            </Modal>
            <Modal visible={loading} footer={null} closable={false} centered>
                <Scanner />
            </Modal>
        </div>
    )
}

export default ProfileView;