import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd'

export interface SendEmailConfirmationProps{
  handleSubmit : (email) => void
}

export default function SendEmailConfirmation(props:SendEmailConfirmationProps) {
  const [form] = Form.useForm()

  const sendMail = () => {
    const email = form.getFieldValue('email')
    props.handleSubmit(email)
  }
  return (
    <div>
      <div className="bg-imge">


        <Row justify='start'>
          <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
            <h1>Forgot Password</h1>
            <p>Reset password in two quick steps</p>
          </Col>
          </Row>
          <Row justify='center'>
          <Col>
            <Form form={form}>
              <Form.Item
                name="email"
                label={<span></span>}
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input placeholder="Email" autoComplete="off" style={{ height: '40px', border: 'none' }} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row justify='center'>
          <Col>
            <div className="gradient-color">
              <Button className='gradient-color' size='large' style={{ height: '40px', borderRadius: '16px' }} onClick={sendMail}>
                Reset password
              </Button>
            </div>
          </Col>
        </Row>

      </div>
    </div>

  )
}
