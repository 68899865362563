import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

export const ShareModel = ({ modalVisible, shareData, handleClose }) => {
  return (
    <>
      <div className={`${"share-modal"} ${modalVisible ? "opened" : "closed"}`}>
        <section className="modal-header">
          <h3 className="modal-title">Share Via</h3>
        </section>
        <section className="modal-body">
          <div className="row">
            <div>
              <WhatsappShareButton url={shareData.url}>
                <WhatsappIcon />
              </WhatsappShareButton>
            </div>
            <div>
              <FacebookShareButton url={shareData.url}><FacebookIcon /></FacebookShareButton>
            </div>
            <div>
              <TelegramShareButton url={shareData.url}><TelegramIcon /></TelegramShareButton>
            </div>
            <div>
              <FacebookMessengerShareButton appId='' url={shareData.url}><FacebookMessengerIcon /></FacebookMessengerShareButton>
            </div>
          </div>

          <div className="row">
            <div>
              <EmailShareButton url={shareData.url}><EmailIcon /></EmailShareButton>
            </div>
            <div>
              <LinkedinShareButton url={shareData.url}><LinkedinIcon /></LinkedinShareButton>
            </div>
            <div>
              <TwitterShareButton url={shareData.url}><TwitterIcon /></TwitterShareButton>
            </div>
            <div>
              <RedditShareButton url={shareData.url}><RedditIcon /></RedditShareButton>
            </div>
          </div>
        </section>
        <section className="modal-footer">
          <div className="modal-footer-link">{shareData.url}</div>
          {/* <button className="modal-footer-button">Copy Link</button> */}
        </section>
      </div>
    </>
  )
}

export default ShareModel;