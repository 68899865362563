import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from '../auth-context/auth-context';

export const ProtecteChildrenWrapper = ({ children }: { children: JSX.Element }) => {
    const { dispatch, authContext } = useAuthState();
    const location = useLocation();
    const userData = localStorage.getItem('userData');
    let data = { isAuthenticated: authContext.isAuthenticated }
    if (!userData) {

    } else {
        data = JSON.parse(userData);
        localStorage.setItem('userData', JSON.stringify(data));
    }
    if (!data.isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}
