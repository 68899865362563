import { Col, Input, Row, Form, Tabs, Button, Modal } from 'antd'
import React, { useState } from 'react';
import { AlertMessages } from '../notifications';
import Scanner from '../scanner';
import './admin-panel.css'
const { TabPane } = Tabs;



export default function AdminPanel() {

    const [formRef] = Form.useForm();
    const [loading, setLoading] = useState(false);


    const scan = async () => {
        if ("NDEFReader" in window) {
            try {
                setLoading(true);
                const windowObject: any = window;
                const ndef = new windowObject.NDEFReader();
                await ndef.scan();

                console.log("Scan started successfully.");

                ndef.onreadingerror = () => {
                    setLoading(false);
                    console.log("Cannot read data from the NFC tag. Try another one?");
                };

                ndef.onreading = (event) => {
                    console.log("NDEF message read.");
                    onReading(event); //Find function below
                };

            } catch (error) {
                setLoading(false);
                console.log(`Error! Scan failed to start: ${error}.`);
            }
        } else {
            setLoading(false);
            AlertMessages.getErrorMessage('NFC Reader not available in your system');
        }
    };

    const onReading = ({ message, serialNumber }) => {
        console.log(serialNumber);
        formRef.setFieldsValue({ serialNumber });
        setLoading(false);
    };


    return (
        <div> <Form
            className="container"
            form={formRef}
        >

            <div className="bg-img">
                <div style={{ paddingTop: '30px' }}>
                    <Row justify='center'>
                        <Col>
                            <h1>
                                ADMIN PANEL
                            </h1>
                        </Col>

                    </Row>
                    <Tabs key='1' tabPosition='top' type={'line'} centered>
                        <TabPane key={'MTO'} tab={<Button size='small' shape='round'>MTO</Button>}>
                            <Row justify='center'>

                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
                                    <Form.Item
                                        name="serialNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Serial Number!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Serial Number" autoComplete="off" style={{ height: '40px', border: 'none' }} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                                </Col>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your First Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="First Name" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                                </Col>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Last Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Last Name" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                                </Col>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                                    name="linkToAttach"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your link!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Link" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                                </Col>
                            </Row>

                        </TabPane>
                        <TabPane key={'MTS'} tab={<Button size='small' shape='round'>MTS</Button>}>
                            <Row justify='center'>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
                                    <Form.Item
                                        name="serialNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Serial Number!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Serial Number" autoComplete="off" style={{ height: '40px', border: 'none' }} disabled />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                                </Col>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your First Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="First Name" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                                </Col>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Last Name!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Last Name" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                                </Col>
                                <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                                    name="linkToAttach"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your link!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Link" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                                </Col>
                            </Row>

                        </TabPane>

                    </Tabs>
                    <Row justify='end'>
                        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button className='gradient-color'
                                    onClick={scan}>Scan</Button>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button className='gradient-color'
                                >Submit</Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </div>
            </div>
            <Modal visible={loading} footer={null} closable={false} centered>
                <Scanner />
            </Modal>
        </Form>
        </div>
    )
}
