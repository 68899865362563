
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/lib/notification';
// This method is used to display multiple Alert messages with maximum number of 3 
notification.config({ maxCount: 3, duration: 3, placement: 'top' });
export class AlertMessages {

  static getErrorMessage = (content: string, placement?: NotificationPlacement) => {
    notification.destroy();
    notification.error(
      {
        message: 'Error',
        description: content,
        placement,
      }
    );
    return false;
  }

  static getSuccessMessage = (content: string, placement?: NotificationPlacement) => {
    notification.destroy();
    notification.success({
      message: 'Success',
      description: content,
      placement,
    });
    return false;
  }

  static getWarningMessage = (content: string, placement?: NotificationPlacement) => {
    notification.destroy();
    notification.warning({
      message: 'Warning',
      description: content,
      placement,
    });
    return false;
  }

  static getInfoMessage = (content: string, placement?: NotificationPlacement) => {
    notification.destroy();
    notification.info({
      message: 'Inform',
      description: content,
      placement,
    });
    return false;
  }

  render() {
    return;
  }
}

export default AlertMessages;
