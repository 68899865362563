export const contactDetailsComponents = {
    contactForm: {
        title: 'Contact',
        header: 'Contact Details',

        landLineNo: 'Land Line No',
        mobileNo: 'Mobile No',
        email: 'Email',

        reqLandLineNo: 'Please enter land line no',
        reqMobileNo: 'Please enter mobile no',
        reqEmail: 'Please enter email',
    },
    contactGrid: {
        title: 'Contact',
        header: 'Contact Details',
        landLineNo: 'Land Line No',
        mobileNo: 'Mobile No',
        email: 'Email',
    }
}