import { useEffect } from 'react';
import AppRoutes from './app-routes';
import { useAuthState } from './components/common';
import { loadUser } from './components/common/auth-context/actions';

function App() {
  const { dispatch } = useAuthState();
  useEffect(() => {
    loadUser(dispatch)
  }, []);

  return (
    <AppRoutes />
  );
}

export default App;
