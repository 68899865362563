export const companyRegistrationIntlCodes = {
    companyRegistrationForm: {
        step1heading:'Company Details',
        step2heading:'Point Of Contact Details',
        step3heading:'Truck Details',
        step4heading:'Preview and Submit',
        truckRegistrationNumber:'Truck Registration Number',
        reqTruckRegNo:'Please Enter Truck Registration Number',
        truckHeadNumber:'Truck Head Number',
        reqTruckHedNumber:'Please Enter Truck Head Number'
    },
    companyRegistrationGrid: {

    }
}