import React, { createContext, PropsWithChildren, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { authReducer } from './reducers';

export type InitialStateType = {
  isAuthenticated: boolean,
  user: any,
  defaultPlant: string,
  allocatedPlants: any,
  token: any,
  menuAccessObject: any
}

export const initialAuthState: InitialStateType = {
  isAuthenticated: false,
  user: {
    userName:'admin'
  },
  defaultPlant: '',
  allocatedPlants: [{
    plantCode: '',
    plantName: '',
    isDefault: true
  }],
  token: null,
  menuAccessObject: []
}

interface AuthContextType {
  authContext: InitialStateType;
  dispatch: React.Dispatch<any>;
}
interface AuthContextProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType>({
  authContext: initialAuthState,
  dispatch: () => null
});

const AuthProvider: React.FC<PropsWithChildren<AuthContextProps>> = ({ children }: { children: React.ReactNode }) => {
  const [authContext, dispatch] = useReducer(authReducer, initialAuthState);

  const value = useMemo(
    () => ({
      authContext, dispatch
    }),
    [authContext]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuthState = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}
export { AuthProvider, AuthContext, useAuthState };

