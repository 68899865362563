import { contactDetailsComponents, kycFormIntlMessages, userBasicDetailsComponents,FileUploadFormIntlMessages,AddressLabelNames } from './common';
import { companyRegistrationIntlCodes } from './mdm/company';
const commonErrorCodes = {
    99995: 'Someone Already modified this record please refresh and continue..',
    99996: 'You could not update a deactivated record',
    99997: 'Records already exist with given data',
    99998: 'No Records Found',
    99999: 'Something went wrong from Server or Database',




    10550: 'All resource and scope level access given to user are retrieved successfully',
    10551: 'No resource and scope level access are mapped to user',
    10552: 'Plants data is mapped to user successfully',
    10553: 'Plants data mapped to user is retrieved successfully',
    10554: 'No plants are mapped to user',
    10555: 'All users from keycloak are retrieved successfully',
    10556: 'No users data found in keycloak',
    10557: 'User To Plant mapping Records already exist,Please Check and update',
    10558: 'Duplicated Plant found',
    10559: 'Plant Cluster mapping Data is mis matched with plant service data',
    10560: 'Given Plant is not available in plant service data',
    10561: 'No policies are mapped to the given role',
    10562: 'Plant unmapped successfully',
};

export const messages = {
    mdm: {
        components: {
            currencyGrid: {
                currencyName: 'Currency Name'


            },
            currencyForm: {
                title: 'Curriences',
                currencyName: 'Currency Name',
                currencyNameReq: 'Please Enter Currency Name'
            },
            countryForm: {
                title: 'Countries',
                countryName: 'Country Name',
                countryNameReq: 'Please Enter Country Name'
            },
            countriesGrid: {
                countryName: 'Country Name'

            },
            basisGrid: {
                basisGrid: 'basisName'
            },
            basisForm: {
                basisForm: 'basisName'
            },
            ...companyRegistrationIntlCodes
        },
        pages: {
            userToPlantMapping: {
                header: 'User to Plants Mapping Details',
                user: 'User',
                formHeader: 'User to Plant Mapping Form',
                cardHeadr: 'User to Plants Mapping Details'
            },
            countryPage: {
                header: 'Countries',
            },
        },
        errorCodes: {
            1: 'Business Partner Category SuccessFully Created',
            2: 'Business Partner Category Updated SuccessFull',
            3: 'Business Partner Categoryes retrieved SuccessFully',
            4: 'Business Partner Category deactivated SuccessFully',
            5: 'Business Partner Category activated SuccessFully',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
            11: 'Country   SuccessFully Created',
            12: '',
            13: 'Country name already exist with given data',
            14: 'Country  Updated SuccessFully',
            15: 'Country  retrieved SuccessFully',
            16: 'Country activated SuccessFully',
            17: 'Country deactivated SuccessFully',
            18: '',
            19: '',
            20: 'Congratulations, your account has been successfully created',
            21: 'Basis retrieved SuccessFully',
            22: 'Basis  Updated SuccessFully',
            23: 'Records already exist with given data',
            24: 'Basis activated SuccessFully',
            25: 'Basis deactivated SuccessFully',
            26: '',
            27: '',
            28: '',
            29: '',
            30: '',
            31: '',
            32: '',
            33: '',
            34: '',
            35: '',
            36: '',
            37: '',
            38: '',
            39: '',
            40: '',
            41: '',
            42: '',
            43: '',
            44: '',
            45: '',
            46: '',
            47: '',
            48: '',
            49: '',
            50: '',
            51: 'Curriences SuccessFully Created',
            52: 'Currencies Updated SuccessFully',
            53: 'Currencies retrieved SuccessFully',
            54: 'Currencies retrieved SuccessFully',
            55: 'Currenices activated SuccessFully',
            56: 'Currenices deactivated SuccessFully',
            57: '',
            58: '',
            59: '',
            60: '',
            61: '',
            62: '',
            63: '',
            64: '',
            65: '',
            66: '',
            67: '',
            68: '',
            69: '',
            70: '',
            71: '',
            72: '',
            73: '',
            74: '',
            75: '',
            76: '',
            77: '',
            78: '',
            79: '',
            80: '',
            81: '',
            82: '',
            83: '',
            84: '',
            85: '',
            86: '',
            87: '',
            88: '',
            89: '',
            90: '',
            91: '',
            92: '',
            93: '',
            94: '',
            95: '',
            96: '',
            97: '',
            98: '',
            99: '',
            100: '',
            101: '',
            102: '',
            103: '',
            104: '',
            105: '',
            106: '',
            107: '',
            108: '',
            109: '',
            110: '',
            111: '',
            112: '',
            113: '',
            114: '',
            115: '',
            116: '',
            117: '',
            118: '',
            119: '',
            120: '',
            121: '',
            122: '',
            123: '',
            124: '',
            125: '',
            126: '',
            127: '',
            128: '',
            129: '',
            130: '',
            131: '',
            132: '',
            133: '',
            134: '',
            135: '',
            136: '',
            137: '',
            138: '',
            139: '',
            140: '',
            141: '',
            142: '',
            143: '',
            144: '',
            145: '',
            146: '',
            147: '',
            148: '',
            149: '',
            150: '',
            151: '',
            152: '',
            153: '',
            154: '',
            155: '',
            156: '',
            157: '',
            158: '',
            159: '',
            160: '',
            161: '',
            162: '',
            163: '',
            164: '',
            165: '',
            166: '',
            167: '',
            168: '',
            169: '',
            170: '',
            171: '',
            172: '',
            173: '',
            174: '',
            175: '',
            176: '',
            177: '',
            178: '',
            179: '',
            180: '',
            181: '',
            182: '',
            183: '',
            184: '',
            185: '',
            186: '',
            187: '',
            188: '',
            189: '',
            190: '',
            191: '',
            192: '',
            193: '',
            194: '',
            195: '',
            196: '',
            197: '',
            198: '',
            199: '',
            200: '',
            201: '',
            202: '',
            203: '',
            204: '',
            205: '',
            206: '',
            207: '',
            208: '',
            209: '',
            210: '',
            211: '',
            212: '',
            213: '',
            214: '',
            215: '',
            216: '',
            217: '',
            218: '',
            219: '',
            220: '',
            221: '',
            222: '',
            223: '',
            224: '',
            225: '',
            226: '',
            227: '',
            228: '',
            229: '',
            230: '',
            231: '',
            232: '',
            233: '',
            234: '',
            235: '',
            236: '',
            237: '',
            238: '',
            239: '',
            240: '',
            241: '',
            242: '',
            243: '',
            244: '',
            245: '',
            246: '',
            247: '',
            248: '',
            249: '',
            250: '',
            251: '',
            252: '',
            253: '',
            254: '',
            255: '',
            256: '',
            257: '',
            258: '',
            259: '',
            260: '',
            261: '',
            262: '',
            263: '',
            264: '',
            265: '',
            266: '',
            267: '',
            268: '',
            269: '',
            270: '',
            271: '',
            272: '',
            273: '',
            274: '',
            275: '',
            276: '',
            277: '',
            278: '',
            279: '',
            280: '',
            281: '',
            282: '',
            283: '',
            284: '',
            285: '',
            286: '',
            287: '',
            288: '',
            289: '',
            290: '',
            291: '',
            292: '',
            293: '',
            294: '',
            295: '',
            296: '',
            297: '',
            298: '',
            299: '',
            300: '',
            301: '',
            302: '',
            303: '',
            304: '',
            305: '',
            306: '',
            307: '',
            308: '',
            309: '',
            310: '',
            311: '',
            312: '',
            313: '',
            314: '',
            315: '',
            316: '',
            317: '',
            318: '',
            319: '',
            320: '',
            321: '',
            322: '',
            323: '',
            324: '',
            325: '',
            326: '',
            327: '',
            328: '',
            329: '',
            330: '',
            331: '',
            332: '',
            333: '',
            334: '',
            335: '',
            336: '',
            337: '',
            338: '',
            339: '',
            340: '',
            341: '',
            342: '',
            343: '',
            344: '',
            345: '',
            346: '',
            347: '',
            348: '',
            349: '',
            350: '',
            ...commonErrorCodes
        },
    },
    common: {
        action: 'Action',
        create: 'Create',
        new: 'new',
        delete: 'Delete',
        update: 'Update',
        edit: 'Edit',
        view: 'View',
        proceed: 'Proceed',
        clear: 'Clear',
        deActivate: 'Deactivate',
        activate: 'Activate',
        add: 'Add',
        save: 'Save',
        search: 'Search',
        selectAll: 'Select All',
        plant: 'Select Plant',
        upload: 'Upload',
        control: 'Control',
        status: 'Status',
        description: 'Description',
        inProgress: 'In progress',
        reqCompany: 'Select company',
        reqCluster: 'Select cluster',
        reqPlant: 'Select plant',
        male: 'Male',
        female: 'Female',
        others: 'Others',
        genderLabel: 'Please select gender',
        success: 'Success',
        error: 'Error',
        warning: 'Warning',
        information: 'Information',
        deActivateConfirmMsg: 'Are you sure to delete Record ?',
        activateConfirmMsg: 'Are you sure to activate Record ?',
        cannotEditDeactivated: 'You cannot edit deactivated Record',
        authService: {
            10550: 'All resource and scope level access given to user are retrieved successfully',
            10551: 'No resource and scope level access are mapped to user',
            10552: 'Plants data is mapped to user successfully',
            10553: 'Plants data mapped to user is retrieved successfully',
            10554: 'No plants are mapped to user',
            10555: 'All users from keycloak are retrieved successfully',
            10556: 'No users data found in keycloak',
            10557: 'User To Plant mapping Records already exist,Please Check and update',
            10558: 'Duplicated Plant found',
            10559: 'Plant Cluster mapping Data is mis matched with plant service data',
            10560: 'Given Plant is not available in plant service data',
            10561: 'No policies are mapped to the given role',
        },
        ...userBasicDetailsComponents,
        ...contactDetailsComponents,
        ...kycFormIntlMessages,
        ...FileUploadFormIntlMessages,
        ...AddressLabelNames
    }
}
