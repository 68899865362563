import { Route, Routes } from 'react-router-dom';
import { ExceptionComponent, LandingPage, LoginComponent, ProfileView, ProtecteChildrenWrapper, SignUpComponent, } from './components/common';
import AdminPanel from './components/common/admin-panel/admin-panel';
import ForgotPassword from './components/common/forgot-password/forgot-password';
import ProfilesList from './components/common/profile-list/profile-list';
import ShareProfile from './components/common/share-profile/share-profile';


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/"
                element={
                    <ProtecteChildrenWrapper>
                        <></>
                    </ProtecteChildrenWrapper>
                }>
            </Route>
            <Route path="/profiles/"
                element={
                    <ProtecteChildrenWrapper>
                        <ProfilesList />
                    </ProtecteChildrenWrapper>
                }>
            </Route>
            <Route path="/profile/"
                element={
                    <ProtecteChildrenWrapper>
                        <ProfileView />
                    </ProtecteChildrenWrapper>
                } >


                {/* Page Not Found */}
                <Route path='/profile/*' element={<ExceptionComponent statusCode={404} statusMessage='Sorry, the page you visited does not exist.' />} />
                <Route path='/profile/403' element={<ExceptionComponent statusCode={403} statusMessage='Sorry, you are not authorized to access this page.' />} />
                {/* End Page Not Found */}
            </Route>
            <Route path="/login" element={<LoginComponent />} />
            <Route path="/signUp" >
                <Route path=":serialNumber" element={<SignUpComponent />} />
                <Route path="" element={<SignUpComponent />} />
            </Route>
            <Route path="/landing" element={<LandingPage />} />
            <Route path='/shareProfile'>
                <Route path=':serialNumber' element={<ShareProfile />} />
            </Route>
            <Route path="/admin-panel" element={<AdminPanel />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />



        </Routes>)
}

export default AppRoutes;