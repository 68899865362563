import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { loginUser, useAuthState } from '../auth-context';
import { useNavigate } from 'react-router-dom';
import DataLynxLogo from '../../images/DataLynx-Logo.png'
import { AuthService } from '../../../services/auth/auth.service';
import { ActionTypes } from '../auth-context/action-types';
import { AlertMessages } from '../notifications';
import './login-component.css'
import Scanner from '../scanner';
import ForgotPassword from '../forgot-password/forgot-password';
import SendEmailConfirmation from '../send-email-confirmation/send-email-confirmation';
import EnterOtpPopUp from '../send-email-confirmation/enter-otp';


export const LoginComponent = () => {
    const [formRef] = Form.useForm();
    const { dispatch } = useAuthState();
    const navigate = useNavigate();
    const service = new AuthService();
    const [loading, setLoading] = useState(false);
    const [openFrgtPwd,setOpenFrgtPwd] = useState(false)
    const [openEnterOtp,setOpenEnterOtp] = useState(false)
    const [emailData,setEmailData] = useState([]);
    const [verificationStatus,setVerificationStatus] = useState(false)



    const handleLogin = async (values: any) => {
        try {
            formRef.validateFields().then(values => {
                login(values);
            });
        } catch (error) {
            console.log(error)
        }
    };

    const login = (formData: any) => {
        setLoading(true)
        service.login(formData).then(res => {
            if (res.status) {
                const data = {
                    loading: false,
                    isAuthenticated: true,
                    user: {
                        userName: res.data.firstName,
                        profileData: res.data.profiles[0],
                        uniqueId: res.data.uniqueId
                    },
                    token: 'token',
                    keycloak: 'keyCloak',
                    defaultPlant: 'defaultPlant',
                    allocatedPlants: ['SAI'],
                    menuAccessObject: [],
                    errorMessage: null,
                    loginTime: new Date(),
                }
                dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
                localStorage.setItem('userData', JSON.stringify(data));
                setLoading(false)
                navigate('/profile/');
            } else {
                setLoading(false)
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            setLoading(false)
            AlertMessages.getErrorMessage(err.message);
        });
    }

    const goToSignUpComponent = () => {
        navigate('/signUp/');
    }

    const handleForgotPassword = () => {
        setOpenFrgtPwd(true)
    }

    const closeFrgtPwdDrawer = () => {
        setOpenFrgtPwd(false)
    }

    const sendOtpToEmail = (email) => {
        const req = {email : email}
        service.sendForgotPasswordMail(req).then((res) => {
            if(res.status){
                setEmailData(res.data)
                setOpenFrgtPwd(false)
                setOpenEnterOtp(true)
            }
        }).catch((err) => {
            setOpenFrgtPwd(false)
        })
    }

    const verificationSucess = () => {
        setVerificationStatus(true)
        navigate('/forgot-password/',{state:{email:emailData[0]?.email }})
    }


    return (
        <div className='login'>
            <Form
                className="container"
                form={formRef}
            >
                <div className="bg-img">
                    <div style={{ paddingTop: '30px' }}>
                        <Row justify='center' gutter={24} >
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <object data={DataLynxLogo} style={{ width: '100%' }}></object>
                            </Col >
                        </Row>
                        <Row justify='center' >
                            <div className='header' style={{ textAlign: 'center' }}>
                                <h1>Login</h1>
                                <p>Please login to your account</p>
                            </div>
                        </Row>
                        <Row justify='center'  >
                            <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Username!',
                                        },
                                        {
                                            type: 'email',
                                            message: " Enter valid email"
                                        }
                                    ]}
                                >
                                    <Input type='email' suffix={<UserOutlined className="site-form-item-icon" />} placeholder="email" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='center' >
                            <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        suffix={<LockOutlined className="site-form-item-icon" />}
                                        type="Password"
                                        placeholder="Password"
                                        autoComplete="off"
                                        style={{ height: '40px', border: 'none', borderRadius: '16px' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='center'>
                            <Form.Item>
                                <div className="gradient-color">
                                    <Button className='gradient-color' size='large' style={{ height: '40px', borderRadius: '16px' }} onClick={handleLogin}>
                                        LOGIN
                                    </Button>
                                </div>
                            </Form.Item>
                        </Row>
                        {/* <Row justify='center' style={{ color: '#fff' }}>
                            Don't have an account ?
                        </Row> */}
                        <Row justify='center'>
                            <a style={{ color: '#fff' }} onClick={handleForgotPassword}><b>Forgot password?</b></a>
                        </Row>
                        <Row justify='center'>
                            <p style={{ marginTop: '2em', color: '#fff' }}>Your digital gateway to smart & contactless sharing</p>
                        </Row>
                    </div>
                </div>
            </Form>
            <Modal visible={loading} footer={null} closable={false} centered>
                <Scanner />
            </Modal>
            <Modal visible={openFrgtPwd} footer={null} closable={true} centered width={'80%'}  onCancel={closeFrgtPwdDrawer}>
                <SendEmailConfirmation handleSubmit={sendOtpToEmail}  />
            </Modal>
            <Modal visible={openEnterOtp} footer={null} closable={true} centered width={'80%'}  onCancel={() =>setOpenEnterOtp(false)}>
                <EnterOtpPopUp email={emailData[0]?.email} setVerificationStatus={verificationSucess}/>
            </Modal>
        </div>
    )
}

export default LoginComponent;