import React from 'react';
import './scanner.css';
import Spinner from '../../images/spinner.gif';

const Scanner = () => {
  return (
    <div className="scanner">
        <div className="scanner-container">
          <img src={Spinner} alt="spinning log" className="scanner-image"/>
          <p className="scanner-text">
            Please wait...
          </p>
        </div>
      </div>
  )
}

export default Scanner;