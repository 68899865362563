import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { loginUser, useAuthState } from '../auth-context';
import { useNavigate, useParams } from 'react-router-dom';
import DataLynxLogo from '../../images/DataLynx-Logo.png';
import KeyIcon from '../../images/keyIcon.svg';
import { AuthService } from '../../../services/auth/auth.service';
import { AlertMessages } from '../notifications';
import Scanner from '../scanner';
import { ActionTypes } from '../auth-context/action-types';
import { useIntl } from 'react-intl';


export const SignUpComponent = () => {
    const [loading, setLoading] = useState(false);
    const [formRef] = Form.useForm();
    const navigate = useNavigate();
    const { formatMessage: fm } = useIntl();
    const service = new AuthService();
    const { serialNumber } = useParams<any>()
    const { dispatch, authContext } = useAuthState();

    useEffect(() => {
        service.checkIfUserExist({ serialNumber: serialNumber }).then((res) => {
            if (res.status && !authContext.isAuthenticated) {
                const data = {
                    loading: false,
                    isAuthenticated: false,
                    user: {
                        userName: res.data.firstName,
                        profileData: res.data.profiles[0],
                        uniqueId: res.data.uniqueId
                    },
                    token: 'token',
                    keycloak: 'keyCloak',
                    defaultPlant: 'defaultPlant',
                    allocatedPlants: ['SAI'],
                    menuAccessObject: [],
                    errorMessage: null,
                    loginTime: null,
                }
                dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
                localStorage.setItem('userData', JSON.stringify(data));
                navigate('/shareProfile/' + res.data.profiles[0].id)
            } else {
                formRef.setFieldsValue({
                    serialNumber: serialNumber
                })
            }
        });
    }, [])



    const handleLogin = () => {
        try {
            formRef.validateFields().then(values => {
                register(values)
            });
        } catch (error) {
            console.log(error)
        }
    };

    const register = (formData: any) => {
        service.registerUser(formData).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Congratulations, your account has been successfully created` }))
                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    }

    const scan = async () => {
        if ("NDEFReader" in window) {
            try {
                setLoading(true);
                const windowObject: any = window;
                const ndef = new windowObject.NDEFReader();
                await ndef.scan();

                console.log("Scan started successfully.");

                ndef.onreadingerror = () => {
                    setLoading(false);
                    console.log("Cannot read data from the NFC tag. Try another one?");
                };

                ndef.onreading = (event) => {
                    console.log("NDEF message read.");
                    onReading(event); //Find function below
                };

            } catch (error) {
                setLoading(false);
                console.log(`Error! Scan failed to start: ${error}.`);
            }
        } else {
            setLoading(false);
            // AlertMessages.getErrorMessage('NFC Reader not available in your system');
        }
    };

    const onReading = ({ message, serialNumber }) => {
        console.log(serialNumber);
        formRef.setFieldsValue({ serialNumber });
        setLoading(false);
    };

    useEffect(() => {
        scan();
    }, []);


    return (
        <div> <Form
            className="container"
            form={formRef}
        >

            <div className="bg-img">
                <div className="login" style={{ paddingTop: '30px' }}>
                    <Row justify='center'  >
                        <Col >
                            <object style={{ width: '100%' }} data={DataLynxLogo}></object>
                        </Col >
                    </Row>
                    <Row justify='center'>
                        <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
                            <Form.Item
                                name="serialNumber"
                                label={<span></span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Serial Number!',
                                    },
                                ]}
                            >
                                <Input suffix={<object data={KeyIcon} className="site-form-item-icon"></object>} placeholder="Serial Number" autoComplete="off" style={{ height: '40px', border: 'none' }} disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                            name="firstName"
                            label={<span></span>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First Name!',
                                },
                            ]}
                        >
                            <Input suffix={<UserOutlined className="site-form-item-icon" />} placeholder="First Name" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                        </Form.Item>
                        </Col>
                        <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                            name="lastName"
                            label={<span></span>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Last Name!',
                                },
                            ]}
                        >
                            <Input suffix={<UserOutlined className="site-form-item-icon" />} placeholder="Last Name" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                        </Form.Item>
                        </Col>
                        <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>                                <Form.Item
                            name="email"
                            label={<span></span>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input suffix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" autoComplete="off" style={{ height: '40px', border: 'none' }} />
                        </Form.Item>
                        </Col>
                        <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
                            <Form.Item
                                name="password"
                                label={<span></span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    suffix={<LockOutlined className="site-form-item-icon" />}
                                    type="Password"
                                    placeholder="Password"
                                    autoComplete="off"
                                    style={{ height: '40px', border: 'none' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
                            <Form.Item
                                name="confirmPassword"
                                label={<span></span>}
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    suffix={<LockOutlined className="site-form-item-icon" />}
                                    type="Password"
                                    placeholder="Confirm Password"
                                    autoComplete="off"
                                    style={{ height: '40px', border: 'none' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='center'>
                        <Col>
                            <div className="gradient-color">
                                <Button className='gradient-color' size='large' style={{ height: '40px', borderRadius: '16px' }} onClick={handleLogin}>
                                    SIGN UP
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row justify='center'>
                        <p style={{ marginTop: '2em', color: '#fff' }}>Your digital gateway to smart & contactless sharing</p>
                    </Row>
                </div>

            </div>
            <Modal visible={loading} footer={null} closable={false} centered>
                <Scanner />

            </Modal>

        </Form></div>
    )
}

export default SignUpComponent;