import { Button, Col, Form, Input, Row } from 'antd'
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthService } from '../../../services/auth/auth.service';
import { AlertMessages } from '../notifications';
import './forgot-password.css'

export interface ForgotPasswordProps {
    email?: string
}
export default function ForgotPassword(props: ForgotPasswordProps) {
    const service = new AuthService
    const navigate = useNavigate();
    const {state} = useLocation()
    console.log(state)
    const updatePassword = (values) => {
        const req = { email: state.email, password: values.password }
        service.updatePassword(req).then((res) => {
            if(res.status){
                AlertMessages.getSuccessMessage('Password Updated Sucessfully');
                navigate('/login/')
            }
        })
    }
    return (
        <div className="bg-img">
            <div>


                <Form onFinish={updatePassword}>
                    <Row justify='center' style={{paddingTop:'10%'}}>
                        <h1 >Choose a new password.</h1>
                       
                    </Row>
                    <Row justify='center'>
                    <p>Create a new password that is at least 8 characters long.</p>
                    </Row>

                    <Row justify='center' style={{ paddingTop: '60px' }}>
                        {/* <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}> */}
                        <Form.Item
                            name="password"
                            label={<span></span>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password
                                type="Password"
                                placeholder="Password"
                                autoComplete="off"
                                style={{ height: '40px', border: 'none' }}
                            />
                        </Form.Item>
                    </Row>
                    {/* </Col> */}
                    {/* <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}> */}
                    <Row justify='center'>


                        <Form.Item
                            name="confirmPassword"
                            label={<span></span>}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                type="Password"
                                placeholder="Confirm Password"
                                autoComplete="off"
                                style={{ height: '40px', border: 'none' }}
                            />
                        </Form.Item>
                        {/* </Col> */}
                    </Row>
                    <Row justify='center'>
                        <Button className='gradient-color' size='large' style={{ height: '40px', borderRadius: '16px' }} htmlType='submit'>
                            Submit
                        </Button>
                    </Row>

                </Form>
            </div>
        </div>

    )
}
